
import { Component, Vue, Prop } from 'vue-property-decorator';
import { DatePicker } from 'element-ui';
import { DATA_TYPE } from '@/modules/reports/constants';
import Item from '../interfaces/item.interface';
import ModalWrapper from './modal-wrapper.vue';
import CustomCheckbox from './ui-kit/custom-checkbox.vue';
import CustomSelect from './ui-kit/custom-select.vue';
import CustomMultiSelect from './ui-kit/custom-multi-select.vue';

export interface ButtonParam {
    onClick: () => any;
    label: string;
}

export interface ColumnParam {
    label: string;
    key: string;
    default: any;
    disabled?: boolean;
    divider?: boolean;
}

export interface PropertyParam extends ColumnParam {
    component: Vue; // NOTE: The component must support v-model
    props: { [k: string]: any; };
}

export interface FilterParam extends ColumnParam {
    options: Item[];
    multiselect?: boolean;
    maxSelected?: number;
    valuesOnly?: boolean;
    filterSelectAll?: (n: any) => any;
}

@Component({
    components: {
        CustomCheckbox,
        CustomMultiSelect,
        CustomSelect,
        ModalWrapper,
        DatePicker,
    },
})
export default class DownloadReportForm extends Vue {
    @Prop({ type: Array, default: () => [] })
    properties!: PropertyParam[];

    @Prop({ type: Array, default: () => [] })
    customColumns!: ColumnParam[];

    @Prop({ type: Array, default: () => [] })
    filters!: FilterParam[];

    @Prop({ type: Array, default: () => [] })
    buttons!: ButtonParam[];

    @Prop({ type: String, default: DATA_TYPE.RATES })
    dataType!: string;

    @Prop({ type: Boolean, default: false })
    isLoading!: boolean;

    @Prop({ type: Object, default: () => ({}) })
    value!: { [k: string]: any };

    form: { [k: string]: any } = {};
    formWatcher: { [k: string]: any; } = {};
    columnsWatcher: { [k: string]: any; } = {};

    errorMessage: string = '';
    infoMessage: string = '';

    get isFormValid() {
        return ![...this.filters, ...this.customColumns, ...this.properties]
            .some(filter => {
                const value = this.form[filter.key];

                if (Array.isArray(value)) {
                    return !value.length;
                }

                return !value;
            });
    }

    isEventDefined(event: string) {
        const { componentOptions } = this.$vnode;
        if (!componentOptions) return false;

        const { listeners } = componentOptions as { listeners: { [k: string]: any } };
        if (!listeners) return false;

        return listeners[event];
    }

    mounted() {
        this.makeFormReactive();
        this.initWatcher();
    }

    error(message: string) {
        this.errorMessage = message;
    }

    message(message: string) {
        this.infoMessage = message;
    }

    close() {
        const { modal } = this.$refs as { modal: ModalWrapper };
        if (!modal) return;

        // @ts-ignore
        modal.triggerClose();
    }

    private makeFormReactive() {
        if (this.customColumns.length) {
            this.$set(this.form, 'columns', {});

            this.customColumns.forEach(column => {
                this.$set(this.form.columns, column.key, column.default);
            });
        }

        [...this.filters, ...this.properties]
            .filter(filter => !filter.divider)
            .forEach(filter => {
                this.$set(this.form, filter.key, filter.default === undefined ? null : filter.default);
            });

        this.$emit('input', this.form);
    }

    private initWatcher() {
        this.formWatcher = new Proxy(this.form, {
            set: (target, key: string, value: any) => {
                this.onFormChanged(key, value);
                return Reflect.set(target, key, value);
            },
        });

        this.columnsWatcher = new Proxy(this.form.columns || {}, {
            set: (target, key: string, value: any) => {
                this.onFormChanged(key, value);
                return Reflect.set(target, key, value);
            },
        });
    }

    private onFormChanged(key: string, value: any) {
        this.errorMessage = '';

        this.$emit('form:change', {
            key,
            value,
            form: this.form,
        });

        this.$emit('input', this.form);
    }
}

@Component
export class DownloadReportControlMixin extends Vue {
    private logRefFormWarn() {
        // eslint-disable-next-line
        console.warn('[DownloadReportForm]', 'ref "form" is not defined');
    }

    protected getForm(callback: (f: DownloadReportForm) => any) {
        const { form } = this.$refs as { form: DownloadReportForm };
        if (!form) return this.logRefFormWarn();
        return callback(form);
    }

    protected triggerFormError(message: string) {
        this.getForm(form => form.error(message));
    }

    protected triggerFormMessage(message: string) {
        this.getForm(form => form.message(message));
    }

    protected closeForm() {
        this.getForm(form => form.close());
    }
}
